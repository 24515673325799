import React, { useState } from "react";
import { Box  } from "@mui/material";
import { getinitiateSession } from "../../ApiService/Service";
// IconButton



function CustomerWalletCardPaymentForm({handleGatewaySession}) {
  const  DIRECTION_COLUMN = 'column';
  const [sessionId, setSessionId] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const initiateSession = async () => {
    try {
      getinitiateSession().then(response => {
        if (response) {
          setSessionId(response.data.SessionId);
          setCountryCode(response.data.CountryCode);
          handleGatewaySession(response.data)
        }
      });
    } catch (error) {
      console.error("Failed to initiate session", error);
    }
  };

  React.useEffect(() => {
    const scriptId = "myfatoorah-script";

    // Check if the script already exists in the DOM
    if (!document.getElementById(scriptId)) {
      // Load MyFatoorah script dynamically
      const script = document.createElement("script");
      script.id = scriptId;
      script.src = "https://demo.myfatoorah.com/cardview/v2/session.js";
      script.async = true;
      script.onload = () => {
        // Initiate session after script loads
        initiateSession();
      };
      document.body.appendChild(script);
    }
  }, []);
  React.useEffect(() => {
    if (sessionId && countryCode) {
      const config = {
        countryCode,
        sessionId,
        cardViewId: "card-element",
        supportedNetworks: "v,m,md,ae",
        style: {
          cardHeight: 150,
          tokenHeight: 150,
          input: {
            color: "black",
            fontSize: "13px",
            fontFamily: "sans-serif",
            inputHeight: "32px",
            inputMargin: "10px",
            borderColor: "transparent",
            borderWidth: "1px",
            borderRadius: "8px",
            boxShadow: "",
            placeHolder: {
              holderName: "Name On Card",
              cardNumber: "Number",
              expiryDate: "MM / YY",
              securityCode: "CVV",
            },
          },
        },
      };
      window.myFatoorah.init(config);
    }
  }, [sessionId, countryCode]);

  return (
    <Box direction={DIRECTION_COLUMN}>
     
        <Box gap={24} style={{ marginTop: "10px" }}>
            <Box direction="column" xs={12} sm={12} lg={8} md={8} gap={24}>
                <div id="card-element" />
            </Box>
        </Box>

    </Box>
  );
}

export default CustomerWalletCardPaymentForm;
