import React, { useState } from "react";
import { Button, Container, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import CustomerWalletCardPaymentForm from "../../components/form/CustomerWalletCardPaymentForm";
import { styled } from "@mui/system";
import { myFatoorahCall } from "../../components/functions/MyFatoora";
import CircularProgress from "@mui/material/CircularProgress";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const StyledContainer = styled(Container)(({ theme }) => ({
  background: "#EAF7FD",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "30vh",
  padding: theme.spacing(2),
  borderRadius: "15px",
  marginTop: theme.spacing(2),
}));

const StyledContainerCheckout = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledTextFeilds = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "white",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "19px",
  fontWeight: "500",
}));

const StyledBottomContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export default function StepTwo({ formValues }) {
  const [gatewaySession, setGatewaySession] = useState(null);
  const [formData, setFormData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handlePageChange = async () => {
    const payment_data = {
      session_id: gatewaySession,
      invoice_value: formValues.discount_value
        ? formValues.package_price.price.total - formValues.discount_value
        : formValues.package_price.price.total,
      call_back_url: `${window.location.origin}/success`,
      error_url: `${window.location.origin}/error`,
    };
    setLoading(true);
    try {
      window.myFatoorah
        .submit()
        .then((response) => {
          console.log("RES::", response);
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line no-console
          setLoading(false);
        });

      setTimeout(() => {
        try {
          myFatoorahCall(formValues, formData, payment_data)
            .then((response) => {
              console.log("Response:", response);
              setTimeout(() => {
                window.open(response.data.payment_url, "_self");
              }, 5000);
            })
            .catch((error) => {
              console.error("Error occurred during API call:", error);
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          console.error("Unexpected error:", error);
        }
      }, 5000);
    } catch (error) {
      console.error("Payment failed", error);
      setLoading(false);
    }
  };

  const handleGatewaySession = (session) => {
    setGatewaySession(session);
  };

  const isFormComplete = () => {
    const requiredFields = [
      "sender_name",
      "sender_address",
      "sender_phone",
      "sender_email",
      "recipient_name",
      "recipient_address",
      "recipient_phone",
      "recipient_email",
    ];
    return requiredFields.every(
      (field) => formData[field] && formData[field] !== ""
    );
  };

  return (
    <>
      <StyledContainer>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h5" gutterBottom>
                Sender
              </StyledTypography>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  label="Name"
                  name="sender_name"
                  value={formData.sender_name}
                  onChange={(event) =>
                    handleChange("sender_name", event.target.value)
                  }
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  label="Address"
                  name="sender_address"
                  value={formData.sender_address}
                  onChange={(event) =>
                    handleChange("sender_address", event.target.value)
                  }
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <PhoneInput
                  disableDropdown={true}
                  country={formValues.sender_country.code}
                  value={formData.sender_phone}
                  onChange={(phone) => handleChange("sender_phone", phone)}
                  inputStyle={{
                    borderRadius: "10px",
                    background: "white",
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                    height: "2.4375em",
                  }}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  label="Email"
                  name="sender_email"
                  value={formData.sender_email}
                  onChange={(event) =>
                    handleChange("sender_email", event.target.value)
                  }
                  type="email"
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h5" gutterBottom>
                Recipient
              </StyledTypography>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  name="recipient_name"
                  value={formData.recipient_name}
                  onChange={(event) =>
                    handleChange("recipient_name", event.target.value)
                  }
                  label="Name"
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  label="Address"
                  name="recipient_address"
                  value={formData.recipient_address}
                  onChange={(event) =>
                    handleChange("recipient_address", event.target.value)
                  }
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <PhoneInput
                  disableDropdown={true}
                  country={formValues.recipient_country.code}
                  value={formData.recipient_phone}
                  onChange={(phone) => handleChange("recipient_phone", phone)}
                  inputStyle={{
                    borderRadius: "10px",
                    background: "white",
                    border: "none",
                    boxShadow: "none",
                    width: "100%",
                    height: "2.4375em",
                  }}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <StyledTextFeilds
                  required
                  className="inputLabels"
                  size="small"
                  name="recipient_email"
                  value={formData.recipient_email}
                  onChange={(event) =>
                    handleChange("recipient_email", event.target.value)
                  }
                  label="Email"
                  type="email"
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>
      <StyledContainerCheckout>
        <StyledTypography className="checkout" variant="h5">
          Checkout
        </StyledTypography>
      </StyledContainerCheckout>
      <StyledContainer>
        <Box sx={{ flexGrow: 1 }}>
          <StyledTypography variant="h5" gutterBottom>
            Card Details
          </StyledTypography>
          <CustomerWalletCardPaymentForm
            handleGatewaySession={handleGatewaySession}
          />
        </Box>
      </StyledContainer>
      <StyledBottomContainer>
        <Button
          size="small"
          variant="contained"
          style={{
            backgroundColor: !isFormComplete()
              ? "rgba(0, 0, 0, 0.12)"
              : "#26ACE2",
            color: "white",
            height: "50%",
            padding: "10px 20px",
            borderRadius: "0",
            position: "relative",
          }}
          onClick={handlePageChange}
          disabled={!isFormComplete() || loading}
        >
          {loading ? <CircularProgress size={18} color="inherit" /> : "Pay Now"}
        </Button>
      </StyledBottomContainer>
    </>
  );
}
