import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCountry } from "../../ApiService/Service";
import { styled } from "@mui/system";

const StyledSelectFields = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: "white",
    borderRadius: "10px",
    "&:focus": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
}));

const FromCountryAutoComplete = (props) => {
  const { label, changeEvent, name } = props;
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getCountry();
        setData(result.data);
      } catch (err) {
        console.log("err", err);
        // setError(err);
      } finally {
        console.log("finished");
        // setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <StyledSelectFields
        name={name}
        disablePortal
        size="small"
        id="combo-box-demo"
        options={data}
        getOptionLabel={(option) => option.name} // Use the name field for display
        onChange={(event, newValue) => changeEvent(name, newValue)} // Pass the entire object to changeEvent
        renderInput={(params) => <TextField {...params} label={`${label} *`} />}
      />
    </>
  );
};
export default FromCountryAutoComplete;
