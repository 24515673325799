import * as React from "react";
import {
  Button,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  Slider,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { styled } from "@mui/system";
import FromCountryAutoComplete from "../../components/form/FromCountryAutoComplete";
import FormCityAutoComplete from "../../components/form/FormCityAutoComplete";
import Autocomplete from "@mui/material/Autocomplete";
import { getStartingFrom, getPromoValidate } from "../../ApiService/Service";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const StyledContainer = styled(Container)(({ theme }) => ({
  background: "#EAF7FD",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "20vh",
  padding: theme.spacing(2),
  borderRadius: "15px",
  marginTop: theme.spacing(2),
}));

const StyledContainerTwo = styled(Container)(({ theme }) => ({
  background: "#EAF7FD",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "20vh",
  padding: theme.spacing(2),
  borderRadius: "15px",
  marginTop: theme.spacing(2),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  // background: 'white',
  marginBottom: theme.spacing(2),
  // border: 'none',
}));

const StyledSliderContainer = styled("div")(({ theme }) => ({
  background: "white",
  padding: theme.spacing(2),
  borderRadius: "6px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(2),
  border: "none",
}));

const StyledSelectFields = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    background: "white",
    borderRadius: "10px",
    "&:focus": {
      background: "white",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
  "& .MuiInputLabel-root": {
    color: "black", // Default label color
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "black", // Label color when focused
  },
  "& .MuiInputLabel-root.MuiInputLabel-shrink": {
    color: "black", // Label color when value is selected
  },
}));

const StyledSelectAutoCompleteFields = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: "white",
    borderRadius: "10px",
    "&:focus": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
}));

const StyledTextFeilds = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    background: "white",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
  "& label.Mui-focused": {
    color: "black",
  },
}));

const StyledBottomContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: "19px",
  fontWeight: "500",
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  ".MuiSlider-valueLabel": {
    backgroundColor: "#137ead",
  },
  color: "#137ead",
}));

const StyleDatePicker = styled(DatePicker)(({ theme }) => ({
  background: "white",
  borderRadius: "10px",
  "& .MuiOutlinedInput-root": {
    border: "none",
    "&:hover": {
      border: "none",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputLabel-root": {
    color: "black",
  },
  "& .MuiSvgIcon-root": {
    // Target the icon class
    color: "#137ead", // Set the desired color
  },
}));

const marks = [
  {
    value: 0.1,
    label: "0.1kg",
  },
  {
    value: 100,
    label: "100kg",
  },
];

function valuetext(value) {
  return `${value}Kg`;
}

export default function StepOne({ handleNextStep, handleFormData }) {
  const [formData, setFormData] = React.useState({});
  const [priceData, setPriceData] = React.useState({});
  const [promoList, setPromoList] = React.useState([]);
  const [promoValue, setPromoValue] = React.useState();
  const [alertMessage, setAlertMessage] = React.useState(false);

  const handleChange = (name, value) => {
    if (name === "dimensions.weight") {
      if (typeof value !== "string") {
        // Handle non-string value cases if needed, or set a default string value
        value = String(value);
      }
      // Remove invalid characters but allow only one decimal point
      let cleanedValue = value.replace(/[^0-9.]/g, "");

      // Handle cases where input starts with a decimal point
      if (cleanedValue.startsWith(".")) {
        cleanedValue = "0" + cleanedValue; // Prepend '0' to decimal-only inputs
      }

      // Check if there is already a decimal point
      const hasDecimal = cleanedValue.includes(".");

      // Only allow one decimal point
      if (hasDecimal) {
        // Split the value into parts
        const parts = cleanedValue.split(".");

        // Join parts while allowing only the first decimal point
        cleanedValue = `${parts[0]}.${parts.slice(1).join("")}`;
      }

      // Split the cleaned value into integer and decimal parts
      const finalParts = cleanedValue.split(".");

      // Remove leading zeros from the integer part
      if (finalParts[0]) {
        finalParts[0] = finalParts[0].replace(/^0+/, "");
        if (finalParts[0] === "") {
          finalParts[0] = "0"; // Ensure at least one zero if integer part is empty
        }
      }

      // Limit decimal places to one digit
      if (finalParts.length === 2 && finalParts[1].length > 1) {
        finalParts[1] = finalParts[1].slice(0, 1);
      }

      // Reconstruct the cleaned value
      cleanedValue = finalParts.join(".");

      // Parse the value to a float
      const numericValue = parseFloat(cleanedValue);

      // Ensure the value is within the range and handle invalid values
      if (isNaN(numericValue) || numericValue < 0 || numericValue > 100) {
        cleanedValue = "";
      } else if (numericValue === 100 && cleanedValue.endsWith(".")) {
        // If the value is 100, remove trailing decimal point
        cleanedValue = "100";
      }

      setFormData({
        ...formData,
        [name]: cleanedValue,
      });
    } else {
      // Handle other fields as before
      if (value !== "") {
        if (value === 0 || value < 0) {
          return setFormData({
            ...formData,
            [name]: "",
          });
        }
      }
      if (name === "sender_country" && formData.sender_city) {
        formData.sender_city.name = "";
        formData.sender_city = null;
      }
      if (name === "recipient_country" && formData.recipient_city) {
        formData.recipient_city.name = "";
        formData.recipient_city = null;
      }
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleKeyPress = (event) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
    ];
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };
  const handleWeightKeyPress = (event) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Tab",
      ".",
    ];
    if (!/[0-9]/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  const isFormComplete = () => {
    const requiredFields = [
      "sender_country",
      "sender_city",
      "recipient_country",
      "recipient_city",
      "dimensions.weight",
      "dimensions.height",
      "dimensions.length",
      "dimensions.width",
      "dimensions.unit",
    ];
    return requiredFields.every(
      (field) => formData[field] && formData[field] !== ""
    );
  };

  React.useEffect(() => {
    if (isFormComplete() && !promoValue) {
      const params = new URLSearchParams();
      params.append("dimensions.weight", formData["dimensions.weight"]);
      params.append("dimensions.length", formData["dimensions.length"]);
      params.append("dimensions.width", formData["dimensions.width"]);
      params.append("dimensions.height", formData["dimensions.height"]);
      params.append("dimensions.unit", formData["dimensions.unit"]);
      params.append("from_country_id", formData.sender_country.id);
      params.append("to_country_id", formData.recipient_country.id);
      params.append("from_longitude", formData.sender_city.lon);
      params.append("to_longitude", formData.recipient_city.lon);
      params.append("from_latitude", formData.sender_city.lat);
      params.append("to_latitude", formData.recipient_city.lat);

      getStartingFrom(params.toString())
        .then((res) => {
          const Price = res.data.list ? res.data.list[0].price.total : "";
          // const Currency = res.data.list ? res.data.list[0].price.currency.code : '';
          if (Price) setPriceData(res.data.list[0].price);

          handleFormData(res.data.list[0], formData);
        })
        .catch((error) => {
          setPriceData({});
          setAlertMessage(true);
        });
    } else {
      console.log("Form is incomplete");
    }
    // eslint-disable-next-line
  }, [formData]); // Trigger the effect whenever formData changes

  const transformResponseToArray = (response) => {
    const { id, code, title, subtitle, benefit_type, discount } = response.data;
    return [{ id, code, title, subtitle, benefit_type, discount }];
  };

  const handlePromoChange = (name, newValue) => {
    if (newValue.length > 2) {
      if (isFormComplete()) {
        const params = new URLSearchParams();
        params.append("dimensions.weight", formData["dimensions.weight"]);
        params.append("dimensions.length", formData["dimensions.length"]);
        params.append("dimensions.width", formData["dimensions.width"]);
        params.append("dimensions.height", formData["dimensions.height"]);
        params.append("dimensions.unit", formData["dimensions.unit"]);
        params.append("from_country_id", formData.sender_country.id);
        params.append("to_country_id", formData.recipient_country.id);
        params.append("from_longitude", formData.sender_city.lon);
        params.append("to_longitude", formData.recipient_city.lon);
        params.append("from_latitude", formData.sender_city.lat);
        params.append("to_latitude", formData.recipient_city.lat);
        params.append("promo_code", newValue);
        params.append("price_id", priceData.id);

        getPromoValidate(params.toString())
          .then((res) => {
            if (res.data) {
              setFormData({
                ...formData,
                [name]: res.data.id,
                discount_value: res.data.discount,
              });

              const promoArray = transformResponseToArray(res);
              setPromoList(promoArray);
            }
          })
          .catch((error) => {
            // Remove discount_value from formData in the catch block
            setFormData((prevFormData) => {
              const { discount_value, ...rest } = prevFormData;
              return rest;
            });
            setPromoList([]);
          });
      } else {
        console.log("Form is incomplete");
      }
    } else {
      setFormData((prevFormData) => {
        const { discount_value, ...rest } = prevFormData;
        return rest;
      });
      setPromoList([]);
    }
  };

  const handleCloseSnackbar = () => {
    setAlertMessage(false);
  };

  return (
    <>
      <style>
        {`
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
            `}
      </style>
      <StyledContainer>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h4" gutterBottom>
                Sender
              </StyledTypography>
              <StyledFormControl
                fullWidth
                size="small"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                <FromCountryAutoComplete
                  labelIds="sender-country-label"
                  ids="sender-country"
                  label="Country"
                  name="sender_country"
                  value={formData.sender_country}
                  changeEvent={handleChange}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <FormCityAutoComplete
                  labelIds="sender-city-label"
                  ids="sender-city"
                  label="City"
                  name="sender_city"
                  value={formData.sender_city}
                  changeEvent={handleChange}
                  countryID={
                    formData.sender_country && formData.sender_country?.id
                  }
                />
              </StyledFormControl>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <StyledTypography variant="h4" gutterBottom>
                Recipient
              </StyledTypography>
              <StyledFormControl
                fullWidth
                size="small"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                <FromCountryAutoComplete
                  labelIds="recipient-country-label"
                  ids="recipient-country"
                  label="Country"
                  name="recipient_country"
                  value={formData.recipient_country}
                  changeEvent={handleChange}
                />
              </StyledFormControl>
              <StyledFormControl fullWidth size="small">
                <FormCityAutoComplete
                  labelIds="recipient-city-label"
                  ids="recipient-city"
                  label="City"
                  name="recipient_city"
                  value={formData.recipient_city}
                  changeEvent={handleChange}
                  countryID={
                    formData.recipient_country && formData.recipient_country?.id
                  }
                />
              </StyledFormControl>
            </Grid>
          </Grid>
        </Box>
      </StyledContainer>

      <StyledContainerTwo style={{ marginTop: "30px" }}>
        <Box sx={{ flexGrow: 1 }}>
          <StyledTypography style={{ marginBottom: "20px" }}>
            Parcel Value
          </StyledTypography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Grid container spacing={2} direction="column">
                {/* First row with three fields */}
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={4} xs={12}>
                      <StyledSlider
                        name="dimensions.weight"
                        className={StyledSliderContainer}
                        aria-label="Always visible"
                        value={formData["dimensions.weight"] || 0.1}
                        getAriaValueText={valuetext}
                        step={0.1}
                        marks={marks}
                        valueLabelDisplay="auto"
                        min={0.1}
                        max={100}
                        onChange={(event, newValue) => {
                          handleChange("dimensions.weight", newValue);
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <StyledTextFeilds
                          required
                          className="inputLabels"
                          size="small"
                          label="Weight (Kg - max 100)"
                          defaultValue="0"
                          name="dimensions.weight"
                          value={formData["dimensions.weight"] || ""}
                          onChange={(event) =>
                            handleChange(
                              "dimensions.weight",
                              event.target.value
                            )
                          }
                          onKeyDown={handleWeightKeyPress}
                          type="text"
                          inputProps={{ maxLength: 4 }}
                        />
                      </StyledFormControl>
                    </Grid>

                    <Grid item lg={3} md={4} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <InputLabel>Package Type *</InputLabel>
                        <StyledSelectFields
                          className="inputLabels"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formData["dimensions.unit"]}
                          onChange={(event) =>
                            handleChange("dimensions.unit", event.target.value)
                          }
                        >
                          {/* <MenuItem value="BOX">BOX</MenuItem> */}
                          <MenuItem value="METRIC">METRIC</MenuItem>
                        </StyledSelectFields>
                      </StyledFormControl>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <StyledTextFeilds
                          required
                          className="inputLabels"
                          size="small"
                          label="Length (cm)"
                          name="dimensions.length"
                          onChange={(event) =>
                            handleChange(
                              "dimensions.length",
                              event.target.value
                            )
                          }
                          type="text"
                          onKeyDown={handleKeyPress}
                          value={formData["dimensions.length"]}
                          inputProps={{ maxLength: 3 }}
                        />
                      </StyledFormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Second row with three fields */}
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item lg={3} md={3} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <StyledTextFeilds
                          required
                          className="inputLabels"
                          size="small"
                          label="Height (cm)"
                          name="dimensions.height"
                          onChange={(event) =>
                            handleChange(
                              "dimensions.height",
                              event.target.value
                            )
                          }
                          type="text"
                          onKeyDown={handleKeyPress}
                          value={formData["dimensions.height"]}
                          inputProps={{ maxLength: 3 }}
                        />
                      </StyledFormControl>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <StyledTextFeilds
                          required
                          className="inputLabels"
                          size="small"
                          label="Width (cm)"
                          name="dimensions.width"
                          onChange={(event) =>
                            handleChange("dimensions.width", event.target.value)
                          }
                          type="text"
                          onKeyDown={handleKeyPress}
                          value={formData["dimensions.width"]}
                          inputProps={{ maxLength: 3 }}
                        />
                      </StyledFormControl>
                    </Grid>
                    <Grid item lg={3} md={3} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyleDatePicker
                            className="inputLabels"
                            label="Pickup Date"
                            slotProps={{ textField: { size: "small" } }}
                            minDate={dayjs()}
                          />
                        </LocalizationProvider>
                      </StyledFormControl>
                    </Grid>
                    <Grid item lg={3} md={4} xs={12}>
                      <StyledFormControl fullWidth size="small">
                        <StyledSelectAutoCompleteFields
                          disablePortal
                          size="small"
                          id="combo-box-demo"
                          options={promoList}
                          // sx={{ width: 300 }}
                          getOptionLabel={(option) => option.code}
                          renderInput={(params) => (
                            <TextField {...params} label="Promo Code" />
                          )}
                          onInputChange={(event, newValue) =>
                            handlePromoChange("promo_id", newValue)
                          }
                          onChange={(event, newValue) =>
                            setPromoValue(newValue)
                          }
                          disabled={
                            !isFormComplete() ||
                            Object.keys(priceData).length === 0
                          }
                        />
                      </StyledFormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </StyledContainerTwo>

      <StyledBottomContainer style={{ marginTop: "20px" }}>
        {promoValue ? (
          <div>
            <StyledTypography
              style={{ fontSize: "34px", fontWeight: "500", margin: "0" }}
            >
              {" "}
              {Object.keys(priceData).length > 0
                ? priceData.currency.code +
                  " " +
                  (priceData.total - promoValue.discount)
                : 0}
            </StyledTypography>
            <StyledTypography
              style={{
                fontSize: "24px",
                fontWeight: "400",
                margin: "0",
                color: "#00000066",
              }}
            >
              Approximate cost
            </StyledTypography>
          </div>
        ) : (
          <div>
            <StyledTypography
              style={{ fontSize: "34px", fontWeight: "500", margin: "0" }}
            >
              {" "}
              {Object.keys(priceData).length > 0
                ? priceData.currency.code + " " + priceData.total
                : 0}
            </StyledTypography>
            <StyledTypography
              style={{
                fontSize: "24px",
                fontWeight: "400",
                margin: "0",
                color: "#00000066",
              }}
            >
              Approximate cost
            </StyledTypography>
          </div>
        )}

        <Button
          size="small"
          variant="contained"
          style={{
            backgroundColor:
              !isFormComplete() || Object.keys(priceData).length === 0
                ? "rgba(0, 0, 0, 0.12)"
                : "#26ACE2",
            color: "white",
            height: "50%",
            padding: "10px 20px",
            borderRadius: "0",
          }}
          onClick={handleNextStep}
          disabled={!isFormComplete() || Object.keys(priceData).length === 0} // Disable when either condition is false
        >
          Book Now
        </Button>
      </StyledBottomContainer>

      <Snackbar
        open={alertMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          // onClose={handleCloseSnackbar}
          severity="error"
          variant="filled"
        >
          Package rule doesn't exist
        </Alert>
      </Snackbar>
    </>
  );
}
