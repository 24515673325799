import { axiosInstance} from "../../config/axiosConfig";


function modifyJson(step1, step2, payment_data) {
   return  {
        "pickup_time_now": true,
        "customer_id": null,
        "supplier_id": null,
        "driver_id": null,
        "attachments": [],
        "payer": "sender",
        "status": "unassigned",
        "logistic_type": "REGULAR",
        "piece_count": 1,
        "payment_type": "wallet",
        "payment_data": {
            "session_id": payment_data.session_id.SessionId,
            "invoice_value": payment_data.invoice_value,
            "call_back_url": payment_data.call_back_url,
            "error_url": payment_data.error_url,
        },
        "charge_items": [
            {
                "charge_type": "cod",
                "charge": null,
                "paid": false
            },
            {
                "charge_type": "service_custom",
                "charge": payment_data.invoice_value,
                "paid": false
            }
        ],
        "promo_id": step1.promo_id,
        "sender_data": {
            "address_type": "residential",
            "name": step2.sender_name,
            "email": step2.sender_email,
            "city": step1.sender_city,
            "country": step1.sender_country,
            "neighborhood": {
                "name": null
            },
            "phone_code": null,
            "phone": step2.sender_phone,
            "second_phone": null,
            "third_phone": null,
            "lat": step1.sender_city.lat,
            "lon": step1.sender_city.lng,
            "geo_address": step2.sender_address,
            "save": false,
            "id": 500790039
        },
        "recipient_data": {
            "address_type": "residential",
            "name": step1.recipient_name,
            "building": "",
            "city":  step1.recipient_city,
            "country": step1.recipient_country,
            "street": "",
            "neighborhood": {
                "name": null
            },
            "phone": step2.recipient_phone,
            "second_phone": null,
            "third_phone": null,
            "lat": step1.recipient_city.lat,
            "lon": step1.recipient_city.lng,
            "geo_address": step2.recipient_address,
            "save": false,
            "id": 500496561,
            "email": step2.recipient_email,
        },
        "dimensions": {
            "width": 1,
            "length": 1,
            "height": 1,
            "unit": "METRIC",
            "weight": 1
        },
        "package_type": {
            "id": step1.package_price.id,
            "package_price": {
                "id": step1.package_price.price.id
            }
        },
        "pickup_timeslot_availability_id": null,
        "pickup_timeslot_time": null,
        "drop_off_timeslot_availability_id": null,
        "drop_off_timeslot_time": null,
        "merchant_promise_date": null,
        "planned_pickup_time": null,
        "planned_delivery_time": null,
        "boxes": [
            {
                "line_items": [
                    {
                        "name": "",
                        "ean13_code": "",
                        "origin_country": "",
                        "price_per_unit": "",
                        "quantity": "1",
                        "tax_code": "",
                        "weight": ""
                    }
                ],
                "fragile": false,
                "height": 0,
                "width": 0,
                "length": 0,
                "index": 1
            }
        ]
    }
}
export const myFatoorahCall = async (step1, step2, payment_data) => {
    let payload = modifyJson(step1, step2, payment_data)
    try {
      const response = await axiosInstance.post(`/api/v3/public/order`, payload);
      return response.data;
    } catch (error) {
      console.error('Error fetching demo data', error);
      throw error;
    }
  };