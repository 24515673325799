import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCity } from "../../ApiService/Service";
import { styled } from "@mui/system";

const StyledSelectFields = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    background: "white",
    borderRadius: "10px",
    "&:focus": {
      background: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
  },
}));

export default function FormCityAutoComplete(props) {
  const { label, changeEvent, countryID, name } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!countryID) return;
      setLoading(true);
      try {
        const result = await getCity(countryID);
        setData(result.data);
      } catch (err) {
        console.log("Error fetching cities:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [countryID]);

  return (
    <>
      <StyledSelectFields
        name={name}
        disablePortal
        size="small"
        id="combo-box-demo"
        options={data}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => changeEvent(name, newValue)}
        renderInput={(params) => <TextField {...params}  label={`${label} *`} />}
        disabled={loading || data.length === 0}
      />
    </>
  );
}
